import React from 'react'
import { StyledSocialMediaSvg } from '../../components/styled'
import { FacebookShareButton } from 'react-share'

const Facebook = ({ link, title }) => (
  <FacebookShareButton url={link} quote={title} aria-label="Facebook share">
    <StyledSocialMediaSvg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 40.2533C31.0457 40.2533 40 31.299 40 20.2533C40 9.2076 31.0457 0.253296 20 0.253296C8.9543 0.253296 0 9.2076 0 20.2533C0 31.299 8.9543 40.2533 20 40.2533ZM21.4451 16.9728H24.7372L24.4204 20.064H21.4451V28.9511H17.5812V20.064H15.2628V16.9728H17.5812V15.3113C17.5812 13.8739 18.153 11.5555 21.1901 11.5555H24.5363V14.6467H22.2179C22.1096 14.6462 22.0024 14.6684 21.9032 14.712C21.804 14.7555 21.7151 14.8195 21.6422 14.8996C21.5694 14.9798 21.5141 15.0744 21.4802 15.1773C21.4462 15.2801 21.4343 15.389 21.4451 15.4968V16.9728Z"
        fill="#4A4AF4"
      />
    </StyledSocialMediaSvg>
  </FacebookShareButton>
)

export default Facebook
