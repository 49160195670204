import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../shared/Layout'
import ArticleTopSection from '../components/ArticleTopSection'
import ArticleImage from '../components/ArticleImage'
import ArticleTopSocialMedia from '../components/ArticleTopSocialMedia'
import ArticleMarkdownBody from '../components/ArticleMarkdownBody'
// import RelatedArticles from '../components/RelatedArticles'
import LandingSubscriptionBottom from '../components/LandingSubscriptionBottom'

const Article = ({ pageContext, data, location }) => {
  const { title, image, category, author, bodyNode, seo, meta } = data.article
  // const relatedArticles = data.allDatoCmsArticle.nodes
  const {
    heading,
    subHeading,
    inputPlaceholder,
    buttonText,
  } = data.datoCmsSubscriptionBanner
  const { footer } = pageContext
  return (
    <Layout seo={seo} footer={footer} location={location}>
      <ArticleTopSection
        category={category.name}
        title={title}
        author={author}
        authorProfilePicture={data.articleForAuthor.author.profilePicture}
        readTime={bodyNode.childMarkdownRemark.timeToRead}
        firstPublishedAt={meta.firstPublishedAt}
      />
      <ArticleImage image={image} />
      <ArticleTopSocialMedia link={location.href} title={title} />
      <ArticleMarkdownBody
        link={location.href}
        title={title}
        bodyNode={bodyNode}
        {...author}
      />
      {/* hiding for after launch when we have enough categories */}
      {/* <RelatedArticles
        lang={lang}
        articles={relatedArticles}
        currentArticleId={id}
      /> */}
      <LandingSubscriptionBottom
        heading={heading}
        subHeading={subHeading}
        inputPlaceholder={inputPlaceholder}
        buttonText={buttonText}
      />
    </Layout>
  )
}

export const query = graphql`
  query articlesQuery($id: String!, $category: String!) {
    article: datoCmsArticle(id: { eq: $id }) {
      entrytitle
      id
      title
      meta {
        firstPublishedAt
      }
      slug
      image {
        fluid {
          ... on DatoCmsFluid {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
        alt
      }
      seo {
        title
        description
        keywords {
          keyword
        }
        ogTitle
        ogDescription
        ogImage {
          url
        }
      }
      category {
        name
        slug
      }
      author {
        name
        profilePicture {
          fixed(width: 80, imgixParams: { fm: "jpg", auto: "compress" }) {
            ... on DatoCmsFixed {
              aspectRatio
              src
              srcSet
              sizes
              base64
              width
              height
            }
          }
          alt
        }
        jobTitle
        linkedinProfileLink
      }
      bodyNode {
        childMarkdownRemark {
          htmlAst
          timeToRead
        }
      }
    }
    allDatoCmsArticle(
      filter: { category: { name: { eq: $category } } }
      limit: 4
    ) {
      nodes {
        id
        slug
        title
        image {
          fluid {
            ... on DatoCmsFluid {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          alt
        }
        category {
          name
          slug
        }
        bodyNode {
          childMarkdownRemark {
            htmlAst
            timeToRead
          }
        }
      }
    }
    datoCmsSubscriptionBanner(entrytitle: { regex: "/Bottom/" }) {
      heading
      subHeading
      inputPlaceholder
      buttonText
    }
    articleForAuthor: datoCmsArticle(id: { eq: $id }) {
      author {
        profilePicture {
          fixed(width: 40, imgixParams: { fm: "jpg", auto: "compress" }) {
            ... on DatoCmsFixed {
              aspectRatio
              src
              srcSet
              sizes
              base64
              width
              height
            }
          }
          alt
        }
      }
    }
  }
`

Article.defaultProps = {}

Article.propTypes = {}

export default Article
