import React, { useState } from 'react'
import { StyledSocialMediaSvg } from '../../components/styled'

const Share = ({ link }) => {
  const [hasCoppied, setHasCoppied] = useState(false)
  const handleClick = link => {
    navigator.clipboard.writeText(link)
    setHasCoppied(true)
    setTimeout(() => setHasCoppied(false), 2000)
  }
  return (
    <>
      {hasCoppied && (
        <span
          style={{
            width: '120px',
            font: 'Inter',
            fontSize: 14,
            backgroundColor: 'black',
            color: '#fff',
            textAlign: 'center',
            borderRadius: '6px',
            padding: '5px 0',
            position: 'absolute',
            zIndex: 1,
            marginRight: 50,
          }}
        >
          Copied to Clipboard
        </span>
      )}
      <StyledSocialMediaSvg
        onClick={() => handleClick(link)}
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 40.2533C31.0457 40.2533 40 31.299 40 20.2533C40 9.2076 31.0457 0.253296 20 0.253296C8.9543 0.253296 0 9.2076 0 20.2533C0 31.299 8.9543 40.2533 20 40.2533ZM24.2809 14.0274C25.6464 12.6619 27.8603 12.6619 29.2259 14.0274C30.5914 15.393 30.5914 17.6069 29.2259 18.9724L24.7212 23.4771C23.4314 24.7691 21.3648 24.8513 19.9764 23.6659C18.588 22.4806 18.3451 20.4266 19.4188 18.9502C19.6624 18.6152 19.5884 18.1461 19.2534 17.9025C18.9184 17.6589 18.4493 17.733 18.2057 18.068C16.6714 20.1778 17.0184 23.1129 19.0024 24.8067C20.9863 26.5005 23.9396 26.3827 25.7827 24.5369L30.2865 20.0331C32.2378 18.0818 32.2378 14.9181 30.2865 12.9668C28.3352 11.0155 25.1715 11.0155 23.2202 12.9668L22.5125 13.6746C22.2196 13.9674 22.2196 14.4423 22.5125 14.7352C22.8054 15.0281 23.2802 15.0281 23.5731 14.7352L24.2809 14.0274ZM15.2785 17.0301C16.568 15.7378 18.6347 15.6551 20.0233 16.8402C21.4119 18.0252 21.6549 20.0791 20.5812 21.5554C20.3376 21.8904 20.4117 22.3595 20.7467 22.6031C21.0817 22.8467 21.5507 22.7727 21.7944 22.4377C23.3287 20.3278 22.9814 17.3927 20.9971 15.6992C19.0127 14.0057 16.0593 14.1239 14.2167 15.9706L9.71335 20.4746C7.7622 22.4257 7.76222 25.589 9.7134 27.54C11.6646 29.4911 14.828 29.4911 16.7792 27.54L17.6587 26.6606C17.9516 26.3677 17.9516 25.8929 17.6587 25.6C17.3658 25.3071 16.8909 25.307 16.598 25.5999L15.7186 26.4793C14.3532 27.8446 12.1394 27.8446 10.774 26.4793C9.40866 25.114 9.40871 22.9004 10.7741 21.5352L15.2785 17.0301Z"
          fill="#4A4AF4"
        />
      </StyledSocialMediaSvg>
    </>
  )
}

export default Share
