import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import styled from 'styled-components'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import CategoryBadge from './CategoryBadge'
import ReadTime from './ReadTime'
import AuthorName from './AuthorName'
import AuthorProfilePic from './AuthorProfilePic'
import { imageFixedDefaultShape, imageFixedShape } from '../shared/proptypes'

const { Heading, Caption } = Typography
const { Row, Col } = Grid

const ArticleTopSectionWrapper = styled(Box)(
  ({ theme }) => `
  margin-top: 36px;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin-top: 60px;
  }
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    margin-top: 64px;
  }
  `
)

const AuthorWrapper = styled(Box)(
  ({ theme }) => `
    margin-top: 16px;
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      margin-top: 24px;
    }
  `
)

const ArticleTopSection = ({
  category,
  title,
  author,
  authorProfilePicture,
  readTime,
  firstPublishedAt,
}) => (
  <>
    <ArticleTopSectionWrapper flexDirection="row" flexAlign="middleCenter">
      <Box marginRight="20px">
        <CategoryBadge marginTop="0px">{category}</CategoryBadge>
      </Box>
      <ReadTime>{readTime} MIN READ</ReadTime>
    </ArticleTopSectionWrapper>
    <Box marginTop={[16, 16, 16, 16, 24]}>
      <Grid>
        <Row horizontalAlign="center">
          <Col xs={12} md={10} xl={8}>
            <Heading variant="--heading-1">{title}</Heading>
          </Col>
        </Row>
      </Grid>
    </Box>
    <AuthorWrapper>
      <Grid>
        <Row horizontalAlign="center">
          <Col xs={0} md={12}>
            <Box marginBottom={8}>
              <AuthorProfilePic image={authorProfilePicture} />
            </Box>
          </Col>
          <Col xs={12}>
            <AuthorName name={author.name} />
          </Col>
          <Col xs={12}>
            <Box marginTop="8px">
              <Caption>
                {dayjs(firstPublishedAt).format('MMMM DD, YYYY')}
              </Caption>
            </Box>
          </Col>
        </Row>
      </Grid>
    </AuthorWrapper>
  </>
)

ArticleTopSection.defaultProps = {
  category: '',
  title: '',
  author: {
    name: '',
  },
  authorProfilePicture: imageFixedDefaultShape,
  readTime: 0,
}

ArticleTopSection.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.shape({
    name: PropTypes.string,
  }),
  authorProfilePicture: imageFixedShape,
  readTime: PropTypes.number,
}

export default ArticleTopSection
