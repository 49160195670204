import React from 'react'
import { StyledSocialMediaSvg } from '../../components/styled'

const LinkedInInverted = ({ link }) => (
  <a target="__blank" href={link}>
    <StyledSocialMediaSvg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 38.7534C30.2173 38.7534 38.5 30.4707 38.5 20.2534C38.5 10.0361 30.2173 1.75342 20 1.75342C9.78273 1.75342 1.5 10.0361 1.5 20.2534C1.5 30.4707 9.78273 38.7534 20 38.7534ZM20 40.2534C31.0457 40.2534 40 31.2991 40 20.2534C40 9.20772 31.0457 0.253418 20 0.253418C8.9543 0.253418 0 9.20772 0 20.2534C0 31.2991 8.9543 40.2534 20 40.2534Z"
        fill="#4A4AF4"
      />
      <path
        d="M15.0966 28.4643H11.3409V17.6993H15.0966V28.4643ZM22.8941 21.2078C22.4555 21.2078 22.0349 21.382 21.7247 21.6922C21.4146 22.0023 21.2403 22.4229 21.2403 22.8615V28.4643H17.2914V17.6993H21.2403V18.928C22.1659 18.1445 23.3353 17.7073 24.5479 17.6916C26.9976 17.6916 28.6978 19.5076 28.6978 22.9543V28.4643H24.5479V22.8615C24.5489 22.6437 24.5069 22.4278 24.4242 22.2263C24.3416 22.0248 24.2199 21.8415 24.0662 21.6872C23.9126 21.5328 23.7299 21.4103 23.5288 21.3267C23.3276 21.2431 23.1119 21.2 22.8941 21.2V21.2078ZM15.1662 13.9745C15.1662 14.3566 15.0529 14.7301 14.8406 15.0478C14.6283 15.3655 14.3266 15.6131 13.9736 15.7594C13.6205 15.9056 13.2321 15.9439 12.8573 15.8693C12.4825 15.7948 12.1383 15.6108 11.8681 15.3406C11.5979 15.0704 11.4139 14.7261 11.3394 14.3514C11.2648 13.9766 11.3031 13.5881 11.4493 13.2351C11.5955 12.8821 11.8432 12.5804 12.1609 12.3681C12.4786 12.1558 12.8521 12.0425 13.2342 12.0425C13.7439 12.0466 14.2314 12.2519 14.5904 12.6138C14.9493 12.9757 15.1508 13.4647 15.1507 13.9745H15.1662Z"
        fill="#4A4AF4"
      />
    </StyledSocialMediaSvg>
  </a>
)
export default LinkedInInverted
