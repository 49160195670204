import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import rehypeReact from 'rehype-react'
import Link from '@pbds/link'
import Box from '@pbds/box'
import { PrimaryButton } from '@pbds/buttons'
import Typography from '@pbds/typography'
import Grid from '@pbds/grid'
import Divider from '@pbds/divider'
import AuthorProfilePic from './AuthorProfilePic'
import LinkedInInverted from '../shapes/socialMedia/LinkedInInverted'
import LinkedIn from '../shapes/socialMedia/LinkedIn'
import Facebook from '../shapes/socialMedia/Facebook'
import Twitter from '../shapes/socialMedia/Twitter'
import Share from '../shapes/socialMedia/Share'
import {
  bodyNodeShape,
  bodyNodeDefaultShape,
  imageFixedDefaultShape,
  imageFixedShape,
} from '../shared/proptypes'

const { Row, Col } = Grid

const { Heading, Deck, Body, Caption } = Typography

const StyledUl = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 16px;
  li {
    margin-bottom: 32px;
  }
`

const StyledOl = styled.ol`
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 16px;
  li {
    margin-bottom: 32px;
  }
`

const StyledBlockQuote = styled('blockquote')(
  ({ color }) => `
    color: ${color}
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  `
)

const UnorderedList = ({ children }) => (
  <StyledUl>
    <Deck>{children}</Deck>
  </StyledUl>
)
const OrderedList = ({ children }) => (
  <StyledOl>
    <Deck>{children}</Deck>
  </StyledOl>
)

const SharedBox = ({ children }) => (
  <Box marginBottom={[40, 40, 40, 40, 48]}>{children}</Box>
)

const SocialWrapper = styled('span')(
  ({ theme }) => `
    margin: 0 8px;
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      margin: 0 16px 0 0;
    }
  `
)

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    p: props => (
      <SharedBox>
        <Deck {...props} variant="--deck-regular" />
      </SharedBox>
    ),
    h1: props => (
      <SharedBox>
        <Heading {...props} variant="--heading-1" />
      </SharedBox>
    ),
    h2: props => (
      <SharedBox>
        <Heading {...props} variant="--heading-2" />
      </SharedBox>
    ),
    h3: props => (
      <SharedBox marginBottom={[8, 8, 16]}>
        <Heading {...props} variant="--heading-3" />
      </SharedBox>
    ),
    h4: props => (
      <SharedBox marginBottom={[8, 8, 16]}>
        <Heading {...props} variant="--heading-4" />
      </SharedBox>
    ),
    img: props => <img alt="" {...props} width="100%"></img>,
    a: props => <Link target="__blank" to={props.href} {...props} />,
    button: props => (
      <SharedBox>
        <PrimaryButton buttonType="a" {...props} />
      </SharedBox>
    ),
    ul: props => (
      <SharedBox>
        <UnorderedList {...props} />
      </SharedBox>
    ),
    ol: props => (
      <SharedBox>
        <OrderedList {...props} />
      </SharedBox>
    ),
    blockquote: props => (
      <SharedBox>
        <StyledBlockQuote {...props}>
          <Heading variant="--heading-2">{props.children}</Heading>
        </StyledBlockQuote>
      </SharedBox>
    ),
    title: props => (
      <SharedBox>
        <Caption color="#212189" {...props} />
      </SharedBox>
    ),
    divider: props => <Box marginBottom={props.size} />,
  },
}).Compiler

const ArticleMarkdownBody = ({
  bodyNode,
  name,
  profilePicture,
  jobTitle,
  linkedinProfileLink,
  link,
  title,
}) => {
  return (
    <Box marginX="auto" maxWidth="600px">
      <Grid>
        <Row>
          <Col>{renderAst(bodyNode.childMarkdownRemark.htmlAst)}</Col>
        </Row>
        <Row>
          <Col horizontalAlign={{ xs: 'center', xl: 'start' }}>
            <Deck
              marginBottom="8px"
              flexAlign={[
                'middleCenter',
                'middleCenter',
                'middleCenter',
                'middleCenter',
                'middleLeft',
              ]}
              variant="--deck-semi-emphasized"
            >
              Share this article
            </Deck>
            <Box marginBottom={[48, 48, 48, 48, 72]}>
              <SocialWrapper>
                <LinkedIn link={link} title={title} />
              </SocialWrapper>
              <SocialWrapper>
                <Facebook link={link} title={title} />
              </SocialWrapper>
              <SocialWrapper>
                <Twitter link={link} title={title} />
              </SocialWrapper>
              <SocialWrapper>
                <Share link={link} />
              </SocialWrapper>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col>
            <Box marginBottom={[48, 48, 48, 48, 63]}>
              <Divider />
            </Box>
          </Col>
        </Row>
        <Row verticalAlign="middle">
          <Col xs={12} xl={8} horizontalAlign={{ xs: 'center', xl: 'start' }}>
            <Box
              flexAlign={[
                'middleCenter',
                'middleCenter',
                'middleCenter',
                'middleCenter',
                'middleLeft',
              ]}
              flexDirection={['column', 'column', 'column', 'column', 'row']}
            >
              <AuthorProfilePic image={profilePicture} />
              <Box
                margin={[
                  '16px 0 0 0',
                  '16px 0 0 0',
                  '16px 0 0 0',
                  '16px 0 0 0',
                  '0 0 0 16px',
                ]}
              >
                <Box marginBottom="8px">
                  <Heading variant="--heading-4">{name}</Heading>
                </Box>
                <Body variant="--body-02-regular">{jobTitle}</Body>
              </Box>
            </Box>
          </Col>
          <Col xs={12} xl={4} horizontalAlign={{ xs: 'center', xl: 'end' }}>
            <Box
              marginTop={['16px', '16px', '16px', '16px', 0]}
              flexDirection="row"
              flexAlign={[
                'middleCenter',
                'middleCenter',
                'middleCenter',
                'middleCenter',
                'middleRight',
              ]}
            >
              <Body
                marginRight="8px"
                color="#4A4AF4"
                variant="--body-02-semi-emphasized"
              >
                Follow on
              </Body>
              <LinkedInInverted link={linkedinProfileLink} />
            </Box>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

ArticleMarkdownBody.defaultProps = {
  bodyNode: bodyNodeDefaultShape,
  name: '',
  profilePicture: imageFixedDefaultShape,
  jobTitle: '',
  linkedinProfileLink: '/',
}

ArticleMarkdownBody.propTypes = {
  bodyNode: bodyNodeShape,
  name: PropTypes.string,
  profilePicture: imageFixedShape,
  jobTitle: PropTypes.string,
  linkedinProfileLink: PropTypes.string,
}

export default ArticleMarkdownBody
