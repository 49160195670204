import React from 'react'
import { StyledSocialMediaSvg } from '../../components/styled'
import { LinkedinShareButton } from 'react-share'

const LinkedIn = ({ link, title }) => (
  <LinkedinShareButton url={link} title={title}>
    <StyledSocialMediaSvg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 40.2533C31.0457 40.2533 40 31.299 40 20.2533C40 9.2076 31.0457 0.253296 20 0.253296C8.9543 0.253296 0 9.2076 0 20.2533C0 31.299 8.9543 40.2533 20 40.2533ZM11.3409 28.4642H15.0966V17.6992H11.3409V28.4642ZM21.7247 21.6921C22.0349 21.3819 22.4555 21.2077 22.8941 21.2077V21.2C23.1119 21.2 23.3276 21.243 23.5288 21.3266C23.7299 21.4102 23.9126 21.5327 24.0662 21.6871C24.2199 21.8415 24.3416 22.0247 24.4242 22.2262C24.5069 22.4278 24.5489 22.6436 24.5479 22.8615V28.4642H28.6978V22.9542C28.6978 19.5076 26.9976 17.6915 24.5479 17.6915C23.3353 17.7073 22.1659 18.1444 21.2403 18.928V17.6992H17.2914V28.4642H21.2403V22.8615C21.2403 22.4229 21.4146 22.0022 21.7247 21.6921ZM14.8406 15.0477C15.0529 14.73 15.1662 14.3565 15.1662 13.9744H15.1507C15.1508 13.4646 14.9493 12.9756 14.5904 12.6137C14.2314 12.2518 13.7439 12.0465 13.2342 12.0424C12.8521 12.0424 12.4786 12.1557 12.1609 12.368C11.8432 12.5803 11.5955 12.882 11.4493 13.235C11.3031 13.5881 11.2648 13.9765 11.3394 14.3513C11.4139 14.7261 11.5979 15.0703 11.8681 15.3405C12.1383 15.6107 12.4825 15.7947 12.8573 15.8692C13.2321 15.9438 13.6205 15.9055 13.9736 15.7593C14.3266 15.6131 14.6283 15.3654 14.8406 15.0477Z"
        fill="#4A4AF4"
      />
    </StyledSocialMediaSvg>
  </LinkedinShareButton>
)

export default LinkedIn
