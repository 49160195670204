import React from 'react'
import Box from '@pbds/box'
import Facebook from '../shapes/socialMedia/Facebook'
import LinkedIn from '../shapes/socialMedia/LinkedIn'
import Twitter from '../shapes/socialMedia/Twitter'
import Share from '../shapes/socialMedia/Share'
import styled from 'styled-components'
import Grid from '@pbds/grid'

const { Row, Col } = Grid

const SharedIconWrapper = styled(Box)(
  ({ theme, first }) => `
  margin: ${first ? '8px 8px 8px 0px' : '8px'};
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      margin: ${first ? '0px 24px 24px 24px' : '24px'};
    }
  `
)

const StyledSocialMediaIcons = styled.div`
  display: none;
  @media screen and (min-width: 1200px) {
    display: inline-block;
    position: absolute;
    left: 15vw;
  }
  @media screen and (min-width: 1300px) {
    display: inline-block;
    position: absolute;
    left: 18vw;
  }
  @media screen and (min-width: 1400px) {
    display: inline-block;
    position: absolute;
    left: 21vw;
  }
  @media screen and (min-width: 1500px) {
    display: inline-block;
    position: absolute;
    left: 23vw;
  }
  @media screen and (min-width: 1600px) {
    display: inline-block;
    position: absolute;
    left: 26vw;
  }
  @media screen and (min-width: 1700px) {
    display: inline-block;
    position: absolute;
    left: 28vw;
  }
`

export const SocialMediaIcons = ({ flexAlign, flexDirection, link, title }) => (
  <Box
    flexAlign={flexAlign}
    marginBottom={[40, 40, 40, 40]}
    flexDirection={flexDirection}
  >
    <SharedIconWrapper first>
      <LinkedIn link={link} title={title} />
    </SharedIconWrapper>
    <SharedIconWrapper>
      <Facebook link={link} title={title} />
    </SharedIconWrapper>
    <SharedIconWrapper>
      <Twitter link={link} title={title} />
    </SharedIconWrapper>
    <SharedIconWrapper>
      <Share link={link} />
    </SharedIconWrapper>
  </Box>
)

const ArticleTopSocialMedia = ({ title, link }) => (
  <>
    <Grid>
      <Row>
        <Col xs={12} xl={0}>
          <SocialMediaIcons
            title={title}
            link={link}
            flexDirection={['row', 'row', 'row', 'row', 'column']}
            flexAlign="middleCenter"
          />
        </Col>
      </Row>
    </Grid>
    <StyledSocialMediaIcons>
      <SocialMediaIcons
        title={title}
        link={link}
        flexDirection={['row', 'row', 'row', 'row', 'column']}
      />
    </StyledSocialMediaIcons>
  </>
)

export default ArticleTopSocialMedia
