import React from 'react'
import { StyledSocialMediaSvg } from '../../components/styled'
import { TwitterShareButton } from 'react-share'

const Twitter = ({ link, title }) => (
  <TwitterShareButton url={link} title={title}>
    <StyledSocialMediaSvg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 40.2533C31.0457 40.2533 40 31.299 40 20.2533C40 9.2076 31.0457 0.253296 20 0.253296C8.9543 0.253296 0 9.2076 0 20.2533C0 31.299 8.9543 40.2533 20 40.2533ZM27.0131 15.4725L28.6359 16.168L27.0131 17.3426V17.984C27.0131 23.6563 21.2249 30.0937 11.3641 25.7119C13.2215 25.7537 15.0455 25.2132 16.5804 24.1664C14.7025 24.0195 13.296 23.78 12.5232 22.2344L14.6484 21.4616C13.8254 21.4131 13.0457 21.077 12.4453 20.512C11.845 19.9469 11.4623 19.189 11.3641 18.3704L13.9065 18.7568C12.7474 17.7264 12.361 16.1808 12.7474 14.1201C14.9653 16.6703 17.3532 18.1618 20.0889 17.5976V16.8248C20.0909 15.9032 20.4579 15.0198 21.1097 14.3681C21.7614 13.7164 22.6447 13.3493 23.5664 13.3473C24.4278 13.3565 25.2547 13.6873 25.8848 14.2746L27.8631 13.6178L27.0131 15.4725Z"
        fill="#4A4AF4"
      />
    </StyledSocialMediaSvg>
  </TwitterShareButton>
)

export default Twitter
