import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import Box from '@pbds/box'
import { imageShape, imageDefaultProps } from '../shared/proptypes'

const StyledArticleImage = styled(Img)(
  ({ theme }) => `
  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;
  }
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    border-radius: 8px;
  }
  `
)

const ArticleImage = ({ image }) => (
  <Box
    maxWidth="1200px"
    marginX="auto"
    marginTop={[32, 32, 40, 40, 64]}
    marginBottom={[32, 32, 40, 40, 48]}
  >
    <StyledArticleImage fluid={image.fluid} />
  </Box>
)

ArticleImage.defaultProps = {
  image: imageShape,
}

ArticleImage.propTypes = {
  image: imageDefaultProps,
}

export default ArticleImage
